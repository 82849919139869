<template>
  <div class="resultPage" ref="myRef">
    <div class="result_img"><img src="../assets/result.png" alt="" /></div>
    <div class="title">问题提交成功！</div>
    <div class="title">相关人员会尽快处理，十分感谢！</div>
    <div class="btn" @click="goBack">确定</div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
export default {
  setup() {
    const myRef = ref(null);
    const router = useRouter();
    onMounted(() => {
      myRef.value.style.height =
        `${document.documentElement.clientHeight}` + "px"; //动态设置HTML元素高度
    });
    const goBack = () => {
      router.go(-1);
    };
    return {
      myRef,
      goBack,
    };
  },
};
</script>

<style lang="scss">
.resultPage {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .result_img {
    width: 108px;
    height: 104px;
    margin-bottom: 40px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .title {
    font-size: 24px;
    color: #333;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .btn {
    width: 170px;
    height: 48px;
    background: #6643ff;
    opacity: 1;
    border-radius: 8px;
    text-align: center;
    line-height: 48px;
    font-size: 18px;
    color: #fff;
    margin-top: 170px;
  }
}
</style>